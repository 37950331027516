// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "controllers"
import alertify from 'alertifyjs'
import 'bootstrap'
import './stylesheets/application'

import $ from 'jquery';

global.jQuery = $;
global.$ = $;
global.alertify = alertify
document.addEventListener("turbolinks:load", () => {

  $('[data-toggle="tooltip"]').tooltip()
  $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    console.log(e.target) // newly activated tab
    console.log(e.relatedTarget) // previous active tab
  })
  console.log('load tab')


  $('.btn-tab-prev').on('click', function(e) {
    e.preventDefault()
    $('#' + $('.nav-item > .active').parent().prev().find('a').attr('id')).tab('show')
  })
  $('.btn-tab-next').on('click', function(e) {
    e.preventDefault()
    $('#' + $('.nav-item > .active').parent().next().find('a').attr('id')).tab('show')
  })

  var totalTabs = $('.nav-tabs li').length;
  var currentTab = 1;
  
  $('#ntxbtn').click(function(e){
    console.log('next')
      e.preventDefault();
      currentTab +=1;
      showHideControls();
      $('#' + $('.nav-item > .active').parent().next().find('a').attr('id')).tab('show')
      $('html, body').animate({
          scrollTop: $('#head-title').offset().top //#head-title is an example. Use the id of your destination on the page
      }, 'slow');
    });
    $('#prevbtn').click(function(e){
      console.log('previous')
      e.preventDefault();
      currentTab -=1;
      showHideControls();
      $('#' + $('.nav-item > .active').parent().prev().find('a').attr('id')).tab('show')
  });
  
  function showHideControls(){
    console.log(currentTab)
    if(currentTab == 1){
      $('#prevbtn').hide();
    } else if(currentTab == totalTabs){
      $('#ntxbtn').hide();
      $('#submitbtn').show();
    } else {
      $('#prevbtn').show();
      $('#ntxbtn').show();
    }
  
  }


})